import styled, { css, device, themeGet } from "@styled";
import orangewaveBg from "@data/images/bg/orangewaveBg.webp";
import quoteBG from "@data/images/bg/quote-bg.png";
import formQuotesBg from "@data/images/bg/formquotes-bg.png";

export const ConatactWrapper = styled.section`
  position: relative;
  isolation: isolate;
  padding: 40px 0 120px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(${formQuotesBg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  ${device.medium} {
    padding: 40px 0 120px;
  }
  ${device.xlarge} {
    padding: 140px 0;
  }
  ${(props) =>
    props.layout === 1 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.secondaryDark")},
        ${themeGet("colors.secondary")}
      );
    `}
  ${(props) =>
    props.layout === 2 &&
    css`
      background: linear-gradient(
        320deg,
        ${themeGet("colors.primaryDark")},
        ${themeGet("colors.primary")}
      );
    `}
  ${(props) =>
    props.layout === 3 &&
    css`
      padding: 166px 0;
      background: url(${orangewaveBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
    `}

    .container {
    ${device.xxlarge} {
      max-width: 1570px;
    }
  }
`;

export const ConatactWrapInner = styled.div`
  max-width: 830px;
  margin: 150px auto 0;
  position: relative;
`;
export const ConatactWrapperOuter = styled.div`
  position: relative;
`;
export const ConatactWrapHead = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
  text-align: center;
  ${device.small} {
    margin-bottom: 100px;
  }
  ${device.large} {
    grid-template-columns: 1fr 2fr;
    text-align: left;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -60px;
    bottom: 0;
    background: url(${quoteBG});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    z-index: -1;
    width: 136px;
    height: 150px;
    ${device.medium} {
      left: 70px;
      width: 136px;
      height: 150px;
    }
    ${device.large} {
      width: 226px;
      height: 238px;
      left: 0;
      top: -60px;
    }
  }
  h1 {
    line-height: 1.2;
    font-size: 38px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 50px;
    ${device.medium} {
      font-size: 50px;
      margin-bottom: 30px;
    }
    ${device.large} {
      margin-bottom: 0;
    }
  }
  p {
    color: #dbb5f6;
    font-size: 18px;
    font-weight: normal;
    margin: 0;
    ${device.small} {
      font-size: 21px;
    }
    a {
      color: ${themeGet("colors.primary")};
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
export const StyledBG = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
`;

export const ContactInfoBox = styled.div`
  text-align: center;
  margin-block-start: 45px;
  .icon {
    color: ${themeGet("colors.primary")};
    font-size: 40px;
  }
  ${device.large} {
    margin-block-start: 0;
  }
`;

export const StyledHeading = styled.h3`
  font-weight: 600;
  position: relative;
  line-height: 1.4;
  padding-inline-start: 34px;
  span {
    color: ${themeGet("colors.primary")};
  }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0px;
    width: 4px;
    height: 94%;
    transform: translateY(-50%);
    background-color: ${themeGet("colors.secondary")};
  }
`;

export const StyledText = styled.p`
  margin-block-start: 15px;
  font-size: 18px;
  margin-inline-start: 34px;
`;

export const StyledInfoTitle = styled.h6`
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-block-end: 10px;
  margin-block-start: 10px;
`;
export const TagLine = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
  display: none;
  span {
    transform: skew(-15deg, 0deg);
    display: inline-block;
    color: ${themeGet("colors.primary")};
  }
  ${device.large} {
    display: block;
  }
`;
